import React from 'react'

import { LoadingDots } from 'components/shared/Loading'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import {
  Alert,
  FormInput,
  FormContainer,
  Uploader,
  FormRow,
  FormCol,
} from 'components/shared/Forms'

export default class VerifyPhoneForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      phone_number: '',
      verification_code: '',
      verificationId: '',
      codeSent: false,
      codeVerified: false,
      incorrectCode: false,
      loading: false,
    }
  }

  onSubmit = async () => {
    if (!this.form.reportValidity()) {
      return
    }
    let {
      phone_number,
      verification_code,
      codeSent,
      loading,
      incorrectCode,
    } = this.state
    let error
    this.setState({ loading: true })
    if (!codeSent) {
      error = await this.props.onSubmit(phone_number)
      this.setState({ codeSent: true, loading: false })
    } else {
      error = await this.props.onSubmit(phone_number, verification_code)
      if (error) {
        this.setState({ incorrectCode: true, loading: false })
      }
    }
    if (error) {
      console.log(error)
      return this.setState({ error })
    }
    this.setState({ codeVerified: true, loading: false })
  }

  resetVerification = () => {
    this.setState({
      codeSent: false,
      incorrectCode: false,
    })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      phone_number,
      codeSent,
      verification_code,
      incorrectCode,
      loading,
    } = this.state

    const isInvalid = phone_number == ''

    return (
      <FormContainer>
        <Card
          title="Verify Phone"
          subtitle="Your number will not be shared pubically and is used for verification purposes."
        >
          <form ref={el => (this.form = el)}>
            <FormRow>
              <FormCol>
                {!codeSent ? (
                  <FormInput label="Phone Number">
                    <input
                      type="tel"
                      autoFocus
                      required
                      maxLength="250"
                      name="phone_number"
                      value={phone_number}
                      onChange={this.onChange}
                    />
                  </FormInput>
                ) : (
                  <FormInput label="Verification Code">
                    <input
                      autoFocus
                      required
                      maxLength="250"
                      name="verification_code"
                      value={verification_code}
                      onChange={this.onChange}
                    />
                  </FormInput>
                )}
              </FormCol>
            </FormRow>
            <LoadingDots loading={loading} />
            {incorrectCode && (
              <Button big full onClick={this.resetVerification}>
                Resend code
              </Button>
            )}
            <Button big full onClick={this.onSubmit} disabled={isInvalid}>
              {!codeSent ? 'Continue' : 'Verify'}
            </Button>
          </form>
          <Alert
            type="success"
            show={codeSent}
            message="Verification code sent. Please enter the 4-digit code."
          />
          <Alert type="error" show={incorrectCode} message="Incorrect code" />
        </Card>
      </FormContainer>
    )
  }
}
