import React from 'react'
import { navigate } from 'gatsby'

import VerifyPhoneForm from 'components/Auth/SignUp/VerifyPhone'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import { AuthService } from 'services'

class VerifyPhonePage extends React.Component {
  onSubmit = async (phone_number, verification_code) => {
    try {
      if (!verification_code) {
        await AuthService.verifyPhone(phone_number)
      } else {
        await AuthService.checkCode(phone_number, verification_code)
        // TODO: Implement proper redirection to previous route
        navigate(`/dashboard/settings`)
      }
    } catch (error) {
      return error
    }
  }

  render() {
    return <VerifyPhoneForm onSubmit={this.onSubmit} />
  }
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false} showBeacon={true}>
    <SEO title="Verify Phone" />
    <VerifyPhonePage location={location} />
  </Layout>
)
